import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import Autosuggest from 'react-autosuggest';

import { closePopup } from '../actions/popup';
import { createAddToOrder } from '../actions/shop';

import { BASE_ZINDEX } from '../popup-factory';

import { escapeRegexCharacters, oauth } from '../utils';

class AddToOrderPopup extends Component {

  constructor(props) {
    super(props);

    this.state = {
      value: '',
      order_id: null,
      suggestions: [],
      orders: props.sales_orders,
      loading: false
    };

    _.bindAll(this, ['onChange', 'onBlur', 'onSuggestionsFetchRequested', 'onSuggestionsClearRequested', 'getSuggestions', 'onSuggestionSelected']);
  }

  onChange(e, { newValue, method }) {
    this.setState({
      value: newValue,
      order_id: null
    });
  }

  onBlur(e, { focusedSuggestion }) {
  }

  onSuggestionsFetchRequested({ value }) {
    this.setState({
      suggestions: this.getSuggestions(value),
      order_id: null
    });
  }

  onSuggestionsClearRequested() {
    this.setState({
      suggestions: []
    });
  }

  onSuggestionSelected(e, { suggestion }) {
    this.setState({
      order_id: suggestion.order_id
    });
  }

  getSuggestions(value) {
    const escapedValue = escapeRegexCharacters(value.trim());
    if ('' === escapedValue) {
      return this.state.orders;
    }
    const regex = new RegExp('^' + escapedValue, 'i');
    return this.state.orders.filter(o => regex.test(o.job_number) || regex.test(o.job_name));
  }

  renderLoading() {
    return (
      <div className='row popup-content column'>
        <div className='small-12 columns'>
          <div className='small-12 text-center'><br /><img src='/images/gears.gif' /><br /></div>
        </div>
      </div>
    );
  }

  render() {
    const {
      onClosePopup,
      onAddToOrder,
      index
    } = this.props;

    const {
      value,
      suggestions,
      order_id,
      orders
    } = this.state;

    const inputProps = {
      value,
      onChange: this.onChange,
      onBlur: this.onBlur,
      placeholder: 'Please type in the number or name of the project you want to add your checkouts to'
    };

    const handleCancel = e => {
      e.preventDefault();
      onClosePopup();
    };

    const handleSave = e => {
      e.preventDefault();
      if (!order_id || !orders.filter(o => o.order_id === order_id).length) {
        return;
      }

      this.setState({ loading: true }, function() {
        this.props.onAddToOrder(order_id);
      });
    };

    const getSuggestionValue = suggestion => { return `#${suggestion.job_number} — ${suggestion.job_name}`; };
    const renderSuggestion = suggestion => { return <div className="suggestion-item">{`#${suggestion.job_number}`} &mdash; {suggestion.job_name}</div>; };
    const renderInputComponent = inputProps => (
      <input {...inputProps} />
    );

    return (
      <div className="reveal small" style={{ display: 'block', height: 'initial', overflow: 'initial', zIndex: BASE_ZINDEX + index }}>
        <div className="row">
          <div className="small-12 columns">
            <h3>Add To Project</h3>
          </div>
        </div>
        {this.state.loading ?
          this.renderLoading()
        :
          [<div className="row">
            <div className="small-12 medium-12 columns assume-identity">
              <Autosuggest
                suggestions={suggestions}
                onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
                onSuggestionsClearRequested={this.onSuggestionsClearRequested}
                getSuggestionValue={getSuggestionValue}
                renderSuggestion={renderSuggestion}
                inputProps={inputProps}
                renderInputComponent={renderInputComponent}
                focusFirstSuggestion={true}
                onSuggestionSelected={this.onSuggestionSelected}
                shouldRenderSuggestions={value => true}
              />
            </div>
          </div>,
          <a className="button alert" style={{ position: 'absolute', right: '5rem', top: '1rem' }} onClick={handleCancel}>Cancel</a>,
          <a className="button" style={{ position: 'absolute', right: '1rem', top: '1rem' }} onClick={handleSave}>Add</a>]
        }
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onClosePopup: () => dispatch(closePopup()),
    onAddToOrder: sales_order_id => {
      dispatch(createAddToOrder(ownProps.order_ids, sales_order_id, ownProps.shop_id));
    },
  };
};

const ConnectedAddToOrderPopup = connect(null, mapDispatchToProps)(AddToOrderPopup);
export default ConnectedAddToOrderPopup;
